/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { buildCollection, buildProperty } from '@camberi/firecms';

const examQuestionsCollection = buildCollection({
  path: 'exam-questions',
  initialSort: ['order', 'asc'],
  properties: {
    order: buildProperty({
      dataType: 'number',
      name: 'Order',
      validation: { required: true },
    }),
    title: buildProperty({
      name: 'Title',
      validation: { required: true },
      dataType: 'string',
      columnWidth: 300,
    }),
    content: buildProperty({
      name: 'Content',
      validation: { required: true },
      dataType: 'string',
      markdown: true,
      columnWidth: 300,
    }),
    initialCode: buildProperty({
      name: 'Initial Code',
      dataType: 'string',
      multiline: true,
      columnWidth: 300,
    }),
  },
  name: 'Exam Questions',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  permissions: ({ authController }) => ({
    edit: !!authController.extra.roles.admin,
    create: !!authController.extra.roles.admin,
    delete: !!authController.extra.roles.admin,
  }),
  inlineEditing: false,
  group: '02. Curriculum',
});

export default examQuestionsCollection;
