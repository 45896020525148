/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/prop-types */
import React from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { Title, Wrap } from './DayEvent.styled';

const DayEvent = ({ event }) => (
  <Wrap color={event.color}>
    <Title>{event.lessonName || event.moduleName}</Title>
    {event.isShortStaffed && <ErrorIcon />}
  </Wrap>
);

export default DayEvent;
