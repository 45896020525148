/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { buildCollection, buildProperty } from '@camberi/firecms';

const lessonsCollection = buildCollection({
  path: 'lessons',
  initialSort: ['order', 'asc'],
  properties: {
    name: buildProperty({
      name: 'Name',
      validation: { required: true },
      dataType: 'string',
      columnWidth: 250,
    }),
    teachingPlan: buildProperty({
      name: 'Teaching Plan',
      validation: { required: true },
      url: true,
      dataType: 'string',
      columnWidth: 250,
    }),
    module: {
      name: 'Module',
      validation: { required: true },
      dataType: 'reference',
      path: 'modules',
      columnWidth: 250,
    },
    ratio: {
      name: 'Teaching Ratio',
      validation: { required: true },
      dataType: 'number',
      columnWidth: 150,
    },
    order: {
      name: 'Order',
      validation: { required: true },
      dataType: 'number',
      columnWidth: 150,
    },
  },
  name: 'Lessons',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  permissions: ({ authController }) => ({
    edit:
      !!authController.extra.roles.admin || !!authController.extra.roles.tutor,
    create:
      !!authController.extra.roles.admin || !!authController.extra.roles.tutor,
    delete:
      !!authController.extra.roles.admin || !!authController.extra.roles.tutor,
  }),
  inlineEditing: false,
  group: '01. Scheduling & Attendance',
});

export default lessonsCollection;
