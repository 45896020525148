/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { buildCollection, buildProperty } from '@camberi/firecms';

const coursesCollection = buildCollection({
  path: 'courses',
  properties: {
    name: buildProperty({
      name: 'Name',
      validation: { required: true },
      dataType: 'string',
    }),
    slug: buildProperty({
      name: 'Slug',
      validation: { required: true },
      dataType: 'string',
    }),
    image: buildProperty({
      // The `buildProperty` method is an utility function used for type checking
      name: 'Image',
      dataType: 'string',
      storage: {
        storagePath: 'images',
        acceptedFiles: ['image/*'],
        maxSize: 1024 * 1024,
        metadata: {
          cacheControl: 'max-age=1000000',
        },
        fileName: (context) => context.file.name,
      },
    }),
    modules: {
      name: 'Modules',
      dataType: 'array',
      of: {
        dataType: 'reference',
        path: 'modules',
      },
    },
    order: buildProperty({
      name: 'Order',
      dataType: 'number',
    }),
  },
  name: 'Courses',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  permissions: ({ authController }) => ({
    edit: !!authController.extra.roles.admin,
    create: !!authController.extra.roles.admin,
    delete: !!authController.extra.roles.admin,
  }),
  inlineEditing: false,
  group: '02. Curriculum',
});

export default coursesCollection;
