/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useDataSource } from '@camberi/firecms';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import usersCollection from '../../collections/users';

const START_DAY = 21;
const END_DAY = 20;

const CohortView = ({
  date,
  events,
}: {
  date: Date;
  events: Record<any, any>[];
}) => {
  const [leading, setLeading] = useState<Record<any, any>[]>([]);
  const dataSource = useDataSource();
  const startDate = moment(date).set('date', START_DAY).subtract(1, 'month');
  console.log(startDate);
  const endDate = moment(date).set('date', END_DAY);
  const eventsForMonth = useMemo(
    () =>
      events
        .filter(
          (event: Record<any, Date>) =>
            moment(event.start).isSameOrAfter(startDate) &&
            moment(event.end).isSameOrBefore(endDate) &&
            event.topup &&
            event.hasLeading
        )
        .sort((a: Record<any, any>, b: Record<any, any>) => a.start - b.start),
    [events]
  );
  console.log('events for month', eventsForMonth);
  const leadingStaff = useMemo(
    () =>
      eventsForMonth.reduce(
        (acc: string[], event) =>
          Array.from(new Set([...acc, ...(event.leading as string[])])),
        []
      ),
    [events]
  );

  useEffect(() => {
    const getData = async () => {
      const leadingUserPromises = leadingStaff.map((user: string) =>
        dataSource.fetchEntity({
          path: 'users',
          entityId: user,
          collection: usersCollection,
        })
      );

      const leadingUsers = (await Promise.all(leadingUserPromises)) as Record<
        any,
        any
      >[];

      setLeading(
        leadingUsers
          .map(
            (user) =>
              ({ id: user.id as string, ...user.values } as Record<any, string>)
          )
          .sort((a: Record<any, string>, b: Record<any, string>): number =>
            a.firstName.localeCompare(b.firstName)
          )
      );
    };

    getData();
  }, [leadingStaff]);

  const rows = leading.map((user) => ({
    firstName: user.firstName as string,
    lastName: user.lastName as string,
    leadingCount: eventsForMonth.filter((event) =>
      (event.leading as string[]).includes(user.id as string)
    ).length,
  }));
  console.log(leading);
  console.log('rows', rows);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">No. of Lectures Led</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: Record<any, any>) => (
            <TableRow
              key={row.id as string}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.firstName as string} {row.lastName as string}
              </TableCell>
              <TableCell align="right">{row.leadingCount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

CohortView.title = (date: Date) => moment(date).format('MMMM YYYY');

CohortView.navigate = (date: Date) => date;
export default CohortView;
