/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { buildCollection, buildProperty } from '@camberi/firecms';

const pagesCollection = buildCollection({
  path: 'pages',
  properties: {
    title: buildProperty({
      name: 'Name',
      validation: { required: true },
      dataType: 'string',
    }),
    content: buildProperty({
      name: 'Content',
      validation: { required: true },
      dataType: 'string',
      markdown: true,
    }),
    tag: buildProperty({
      name: 'Tag (ActiveCampaign)',
      validation: { required: false },
      dataType: 'string',
    }),
  },
  name: 'Pages',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  permissions: ({ authController }) => ({
    edit:
      !!authController.extra.roles.admin || !!authController.extra.roles.tutor,
    create:
      !!authController.extra.roles.admin || !!authController.extra.roles.tutor,
    delete:
      !!authController.extra.roles.admin || !!authController.extra.roles.tutor,
  }),
  inlineEditing: false,
  group: '02. Curriculum',
});

export default pagesCollection;
