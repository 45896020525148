/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { buildCollection, buildProperty } from '@camberi/firecms';

const modulesCollection = buildCollection({
  path: 'modules',
  properties: {
    name: buildProperty({
      name: 'Name',
      validation: { required: true },
      dataType: 'string',
    }),
    slug: buildProperty({
      name: 'Slug',
      validation: { required: true },
      dataType: 'string',
    }),
    image: buildProperty({
      // The `buildProperty` method is an utility function used for type checking
      name: 'Image',
      dataType: 'string',
      storage: {
        storagePath: 'images',
        acceptedFiles: ['image/*'],
        maxSize: 1024 * 1024,
        metadata: {
          cacheControl: 'max-age=1000000',
        },
        fileName: (context) => context.file.name,
      },
    }),
    chapters: {
      name: 'Chapters',
      dataType: 'array',
      of: {
        dataType: 'reference',
        path: 'chapters',
      },
    },
    color: buildProperty({
      name: 'Colour',
      dataType: 'string',
      columnWidth: 200,
    }),
    bookable: buildProperty({
      name: 'Bookable?',
      dataType: 'boolean',
    }),
    topup: buildProperty({
      name: 'Lecture Topup?',
      dataType: 'boolean',
    }),
    hasLeading: buildProperty({
      name: 'Has Leading?',
      dataType: 'boolean',
    }),
    order: buildProperty({
      name: 'Order',
      dataType: 'number',
      columnWidth: 100,
    }),
  },
  name: 'Modules',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  permissions: ({ authController }) => ({
    edit:
      !!authController.extra.roles.admin || !!authController.extra.roles.tutor,
    create:
      !!authController.extra.roles.admin || !!authController.extra.roles.tutor,
    delete:
      !!authController.extra.roles.admin || !!authController.extra.roles.tutor,
  }),
  inlineEditing: false,
  group: '02. Curriculum',
});

export default modulesCollection;
