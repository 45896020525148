/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { useAuthController, useDataSource } from '@camberi/firecms';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Avatar,
  Box,
  Button,
  Chip,
  LinearProgress,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { getApp } from 'firebase/app';
import {
  doc,
  collection,
  DocumentReference,
  getFirestore,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import cohortsCollection from '../../collections/cohorts';
import lessonsCollection from '../../collections/lessons';
import modulesCollection from '../../collections/modules';
import shiftsCollection from '../../collections/shifts';
import usersCollection from '../../collections/users';

const TutorView = ({
  entity,
  modifiedValues,
}: {
  entity?: any;
  modifiedValues?: any;
}) => {
  const dataSource = useDataSource();
  const authController = useAuthController();
  const [loading, setLoading] = useState<boolean>(true);
  const [mod, setModule] = useState<any>(null);
  const [cohort, setCohort] = useState<any>(null);
  const [lesson, setLesson] = useState<any>(null);
  const [leading, setLeading] = useState<string[]>([]);
  const [supporting, setSupporting] = useState<string[]>([]);
  const app = getApp();
  const db = getFirestore(app);

  useEffect(() => {
    if (!entity) {
      return;
    }

    const getData = async () => {
      const newModule = await dataSource.fetchEntity({
        path: 'modules',
        entityId: entity.values.module.id,
        collection: modulesCollection,
      });

      setModule(newModule);

      if (entity.values.cohort) {
        const newCohort = await dataSource.fetchEntity({
          path: 'cohorts',
          entityId: entity.values.cohort.id,
          collection: cohortsCollection,
        });

        setCohort(newCohort);
      }

      if (entity.values.lesson) {
        const newLesson = await dataSource.fetchEntity({
          path: 'lessons',
          entityId: entity.values.lesson.id,
          collection: lessonsCollection,
        });

        setLesson(newLesson);
      }

      const leadingUserPromises = (modifiedValues?.leading || []).map(
        (user: any) =>
          dataSource.fetchEntity({
            path: 'users',
            entityId: user.id,
            collection: usersCollection,
          })
      );
      const supportingUserPromises = (modifiedValues?.supporting || []).map(
        (user: any) =>
          dataSource.fetchEntity({
            path: 'users',
            entityId: user.id,
            collection: usersCollection,
          })
      );

      const leadingUsers = await Promise.all(leadingUserPromises);
      setLeading(leadingUsers.map((user) => ({ id: user.id, ...user.values })));

      const supportingUsers = await Promise.all(supportingUserPromises);
      setSupporting(
        supportingUsers.map((user) => ({ id: user.id, ...user.values }))
      );

      setLoading(false);
    };

    getData();
  }, [modifiedValues?.leading, modifiedValues?.supporting]);

  const handleBook = async (role: string) => {
    try {
      await updateDoc(doc(db, 'shifts', entity.id as string), {
        [role]: arrayUnion(
          doc(db, 'users', authController.user?.uid as string)
        ),
      });
    } catch (ex) {
      console.error(ex);
      console.log(authController.user?.uid);
    }
  };

  const handleRemove = async (role: string) => {
    await updateDoc(doc(db, 'shifts', entity.id as string), {
      [role]: arrayRemove(doc(db, 'users', authController.user?.uid as string)),
    });
  };

  if (loading) {
    return <LinearProgress />;
  }

  return (
    // This is a custom component that you can build as any React component
    <div>
      {cohort && (
        <Box sx={{ padding: 4, pb: 0 }}>
          <Stack direction="row" spacing={2}>
            {lesson?.values?.name && (
              <Chip variant="outlined" label={mod?.values?.name} />
            )}
            <Chip
              variant="outlined"
              label={`${
                cohort.values.students?.length || 'Unknown number of'
              } students`}
            />
            <Chip
              variant="outlined"
              label={`1:${lesson?.values?.ratio || 7} target teaching ratio`}
              color={
                cohort.values.students?.length /
                  (leading.length + supporting.length) >
                (lesson?.values?.ratio || 7)
                  ? 'error'
                  : 'success'
              }
            />
            {mod?.values.name && (
              <Chip
                variant="outlined"
                label={
                  mod?.values.topup
                    ? 'Eligible for lecture topup'
                    : 'Not eligible for lecture topup'
                }
                color={mod?.values.topup ? 'success' : 'error'}
              />
            )}
          </Stack>
        </Box>
      )}
      <Box sx={{ padding: 4, pb: 0 }}>
        {lesson?.values?.name ? (
          <Link href={lesson?.values?.teachingPlan} target="_blank">
            <Typography variant="h3" gutterBottom>
              {lesson?.values?.name}
              <OpenInNewIcon />
            </Typography>
          </Link>
        ) : (
          <Typography variant="h3" gutterBottom>
            {mod?.values.name}
          </Typography>
        )}
      </Box>
      {mod?.values.hasLeading && (
        <Box sx={{ padding: 4, pb: 0 }}>
          <Typography variant="h6" gutterBottom>
            Leading
          </Typography>
          <Stack direction="row" spacing={1}>
            {leading.map((user: any) => (
              <Chip
                key={user.id}
                avatar={<Avatar>{user.firstName[0]}</Avatar>}
                label={`${user.firstName} ${user.lastName}`}
                onDelete={
                  authController.user?.uid === user.id
                    ? () => handleRemove('leading')
                    : undefined
                }
                color={
                  authController.user?.uid === user.id ? 'secondary' : 'default'
                }
              />
            ))}
          </Stack>
          {leading.every((user: any) => user.id !== authController.user?.uid) &&
            supporting.every(
              (user: any) => user.id !== authController.user?.uid
            ) && (
              <Button
                variant="contained"
                size="small"
                sx={{ mt: 2 }}
                color="success"
                onClick={() => handleBook('leading')}
              >
                Lead Session
              </Button>
            )}
        </Box>
      )}
      <Box sx={{ padding: 4 }}>
        <Typography variant="h6" gutterBottom>
          Supporting
        </Typography>
        <Stack direction="row" spacing={1}>
          {supporting.map((user: any) => (
            <Chip
              key={user.id}
              avatar={<Avatar>{user.firstName[0]}</Avatar>}
              label={`${user.firstName} ${user.lastName}`}
              onDelete={
                authController.user?.uid === user.id
                  ? () => handleRemove('supporting')
                  : undefined
              }
              color={
                authController.user?.uid === user.id ? 'secondary' : 'default'
              }
            />
          ))}
        </Stack>
        {leading.every((user: any) => user.id !== authController.user?.uid) &&
          supporting.every(
            (user: any) => user.id !== authController.user?.uid
          ) && (
            <Button
              variant="contained"
              size="small"
              sx={{ mt: 2 }}
              color="success"
              onClick={() => handleBook('supporting')}
            >
              Support Session
            </Button>
          )}
      </Box>
    </div>
  );
};

export default TutorView;
