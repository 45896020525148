/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import styled from 'styled-components';

export const Wrap = styled.div`
  background-color: ${({ color }) => color || '#000'};
  border-radius: 2px;
  font-size: 11px;
  padding: 3px 5px;
  line-height: 1.2;
  display: flex;
  position: relative;

  svg {
    position: absolute;
    top: 1px;
    height: 17px;
    right: -3px;
  }
`;

export const Title = styled.h4`
  font-size: 11px;
  font-weight: 600;
  margin: 0;
  margin-right: 5px;
`;

export const Cohort = styled.div``;
