/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import React, { useCallback, useEffect, useMemo } from 'react';

import { EmailAuthProvider, User as FirebaseUser } from 'firebase/auth';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  CircularProgressCenter,
  createCMSDefaultTheme,
  FirebaseAuthController,
  FirebaseLoginView,
  FireCMS,
  NavigationRoutes,
  Scaffold,
  SideDialogs,
  useFirebaseAuthController,
  useBuildModeController,
  useFirebaseStorageSource,
  useFirestoreDataSource,
  useInitialiseFirebase,
  useValidateAuthenticator,
  CMSView,
} from '@camberi/firecms';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'typeface-rubik';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@fontsource/ibm-plex-mono';

// Collections
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import lessonsCollection from './collections/lessons';
import usersCollection from './collections/users';
import cohortsCollection from './collections/cohorts';
import modulesCollection from './collections/modules';
import shiftsCollection from './collections/shifts';
import coursesCollection from './collections/courses';
import chaptersCollection from './collections/chapters';
import pagesCollection from './collections/pages';
import examQuestionsCollection from './collections/examQuestionsCollection';

// Custom views
import Rota from './views/rota';

const DEFAULT_SIGN_IN_OPTIONS = [EmailAuthProvider.PROVIDER_ID];

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
};

const collections = [
  lessonsCollection,
  coursesCollection,
  modulesCollection,
  chaptersCollection,
  pagesCollection,
  shiftsCollection,
  usersCollection,
  cohortsCollection,
  examQuestionsCollection,
];

const customViews: CMSView[] = [
  {
    path: 'rota',
    name: 'Rota',
    description: 'Book shifts and track attendance',
    group: '01. Scheduling & Attendance',
    // This can be any React component
    view: <Rota />,
  },
];

export default function App() {
  const signInOptions = DEFAULT_SIGN_IN_OPTIONS;

  const {
    firebaseApp,
    firebaseConfigLoading,
    configError,
    firebaseConfigError,
  } = useInitialiseFirebase({ firebaseConfig });

  const authController: FirebaseAuthController = useFirebaseAuthController({
    firebaseApp,
    signInOptions,
  });

  const dataSource = useFirestoreDataSource({
    firebaseApp,
    // You can add your `FirestoreTextSearchController` here
  });

  const storageSource = useFirebaseStorageSource({ firebaseApp });

  const modeController = useBuildModeController();
  const theme = useMemo(
    () => createCMSDefaultTheme({ mode: modeController.mode }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { authLoading, canAccessMainView, notAllowedError } =
    useValidateAuthenticator({
      authController,
      authentication: useCallback(async ({ user }: { user: any }) => {
        const idTokenResult = await user?.getIdTokenResult();

        authController.setExtra({
          roles: {
            admin: !!idTokenResult?.claims?.admin,
            tutor: !!idTokenResult?.claims?.tutor,
          },
        });

        return (
          !!idTokenResult?.claims?.tutor ||
          !!idTokenResult?.claims?.admin ||
          false
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []),
      dataSource,
      storageSource,
    });

  if (configError) {
    return <div>{configError}</div>;
  }

  if (firebaseConfigError) {
    return (
      <div>
        It seems like the provided Firebase config is not correct. If you are
        using the credentials provided automatically by Firebase Hosting, make
        sure you link your Firebase app to Firebase Hosting.
      </div>
    );
  }

  if (firebaseConfigLoading || !firebaseApp) {
    return <CircularProgressCenter />;
  }

  const renderComponent = (loading: boolean) => {
    if (loading) {
      return <CircularProgressCenter />;
    }
    if (!canAccessMainView) {
      return (
        <FirebaseLoginView
          allowSkipLogin={false}
          signInOptions={signInOptions}
          firebaseApp={firebaseApp}
          authController={authController}
        />
      );
    }
    return (
      <Scaffold name="Codes Team Dashboard">
        <NavigationRoutes />
        <SideDialogs />
      </Scaffold>
    );
  };

  return (
    <Router>
      <FireCMS
        authController={authController}
        collections={collections}
        views={customViews}
        modeController={modeController}
        dataSource={dataSource}
        storageSource={storageSource}
        entityLinkBuilder={({ entity }) =>
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `https://console.firebase.google.com/project/${firebaseApp.options.projectId}/firestore/data/${entity.path}/${entity.id}`
        }
      >
        {({ loading }) => (
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {renderComponent(loading)}
          </ThemeProvider>
        )}
      </FireCMS>
    </Router>
  );
}
