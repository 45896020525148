/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import Grid from '@mui/material/Unstable_Grid2';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
  buildCollection,
  buildProperty,
  useAuthController,
  useDataSource,
  useSideEntityController,
} from '@camberi/firecms';
import { Wrap } from './Rota.styled';
import 'moment-timezone';
import DayEvent from '../../components/day-event';
import lessonsCollection from '../../collections/lessons';
import TutorView from './TutorView';
import CohortView from './CohortView';

moment.tz.setDefault('Europe/London');
// eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
const localizer = momentLocalizer(moment);

// const events = [
//   {
//     id: 0,
//     title: 'All Day Event very long title',
//     allDay: true,
//     start: new Date(2022, 7, 25),
//     end: new Date(2022, 7, 25),
//   },
// ];

const Rota = () => {
  const [view, setView] = useState('all');
  const [cohortMap, setCohortMap] = useState<Record<string, any> | null>(null);
  const [moduleMap, setModuleMap] = useState<Record<string, any> | null>(null);
  const [shifts, setShifts] = useState<any[]>([]);
  const [lessonMap, setLessonMap] = useState<any[]>([]);
  const [selectedModules, setSelectedModules] = useState<string[]>([]);
  const sideEntityController = useSideEntityController();
  const dataSource = useDataSource();
  const authController = useAuthController();

  const cohortsCollection = buildCollection({
    path: 'cohorts',
    name: 'Cohorts',
    properties: {
      startDate: buildProperty({
        dataType: 'string',
        name: 'Name',
        validation: { required: true },
      }),
    },
  });

  const modulesCollection = buildCollection({
    path: 'modules',
    name: 'Modules',
    properties: {
      startDate: buildProperty({
        dataType: 'string',
        name: 'Name',
        validation: { required: true },
      }),
    },
  });

  const shiftsCollection = buildCollection({
    path: 'shifts',
    name: 'Shifts',
    views: [
      {
        path: 'preview',
        name: 'Booking',
        builder: TutorView,
      },
    ],
    properties: {
      startDate: buildProperty({
        dataType: 'date',
        name: 'Start Date',
        mode: 'date_time',
        validation: { required: true },
        columnWidth: 190,
      }),
      endDate: buildProperty({
        dataType: 'date',
        name: 'End Date',
        mode: 'date_time',
        validation: { required: true },
        columnWidth: 190,
      }),
      cohort: {
        name: 'Cohort',
        validation: { required: true },
        dataType: 'reference',
        path: 'cohorts',
        columnWidth: 250,
      },
      module: {
        name: 'Module',
        validation: { required: true },
        dataType: 'reference',
        path: 'modules',
        columnWidth: 250,
      },
      lesson: {
        name: 'Lesson',
        dataType: 'reference',
        path: 'lessons',
        columnWidth: 250,
      },
      leading: {
        name: 'Leading',
        dataType: 'array',
        of: {
          dataType: 'reference',
          path: 'users',
          previewProperties: ['firstName', 'lastName'],
        },
      },
      supporting: {
        name: 'Supporting',
        dataType: 'array',
        of: {
          dataType: 'reference',
          path: 'users',
          previewProperties: ['firstName', 'lastName'],
        },
      },
    },
    permissions: ({ authController: permissions }) => ({
      edit: !!permissions.extra.roles.admin,
      create: !!permissions.extra.roles.admin,
      delete: !!permissions.extra.roles.admin,
    }),
  });

  useEffect(() => {
    const getShifts = async () => {
      const cohorts = await dataSource.fetchCollection({
        path: 'cohorts',
        collection: cohortsCollection,
      });

      const modules: any[] = await dataSource.fetchCollection({
        path: 'modules',
        collection: modulesCollection,
      });

      const allShifts: any[] = await dataSource.fetchCollection({
        path: 'shifts',
        collection: shiftsCollection,
      });

      const allLessons: any[] = await dataSource.fetchCollection({
        path: 'lessons',
        collection: lessonsCollection,
      });

      setCohortMap(
        cohorts.reduce(
          (prevObj, cohort) => ({
            ...prevObj,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            [cohort.id]: cohort.values || '',
          }),
          {}
        )
      );

      setModuleMap(
        modules
          .filter((mod) => !!mod.values.bookable)
          .reduce(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            (prevObj: any, mod) => ({
              ...prevObj,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              [mod.id]: {
                name: mod.values.name || '',
                color: mod.values.color,
                order: mod.values.order,
                topup: mod.values.topup,
                hasLeading: mod.values.hasLeading,
              },
            }),
            {}
          )
      );

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setShifts(
        allShifts.map((shift) => ({
          id: shift.id,
          startDate: shift.values.startDate,
          endDate: shift.values.endDate,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          cohort: shift.values.cohort?.id || null,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          module: shift.values.module?.id || null,
          lesson: shift.values.lesson?.id || null,
          leading: shift.values.leading || [],
          supporting: shift.values.supporting || [],
          mine:
            (shift.values.leading &&
              (shift.values.leading as any[])
                .map((s: Record<string, any>) => s.id as string)
                .includes(authController.user?.uid as string)) ||
            (shift.values.supporting &&
              (shift.values.supporting as any[])
                .map((s: Record<string, any>) => s.id as string)
                .includes(authController.user?.uid as string)),
        }))
      );

      setLessonMap(
        allLessons
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          .map((lesson) => ({
            id: lesson.id,
            ...lesson.values,
          }))
          .reduce(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            (prevObj, lesson) => ({
              ...prevObj,
              [lesson.id]: lesson,
            }),
            {}
          )
      );

      const lsSelectedModules = JSON.parse(
        window.localStorage.getItem('selected-modules') || '[]'
      );

      setSelectedModules(
        modules
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          .map((mod: any) => mod.id)
          .filter((mod) => {
            if (!lsSelectedModules.length) {
              return true;
            }

            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            return lsSelectedModules.includes(mod);
          })
      );
    };

    getShifts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSetView = (event: any, viewName: string) => setView(viewName);

  const events = shifts
    .filter((shift) =>
      view === 'mine' ? shift.mine : selectedModules.includes(shift.module)
    )
    .map((shift) => ({
      id: shift.id,
      title: `${
        cohortMap?.[shift.cohort] ||
        moduleMap?.[shift.module]?.name ||
        'Missing Data'
      }`,
      moduleName: moduleMap && moduleMap[shift.module]?.name,
      lessonName: lessonMap[shift.lesson]?.name,
      cohortName: cohortMap?.[shift.cohort]?.name || 'All cohorts',
      allDay: false,
      start: new Date(shift.startDate),
      end: new Date(shift.endDate),
      color: moduleMap?.[shift.module]?.color,
      leading: (shift.leading as Record<any, string>[]).map((user) => user.id),
      supporting: (shift.supporting as Record<any, string>[]).map(
        (user) => user.id
      ),
      topup: !!(moduleMap && moduleMap[shift.module]?.topup),
      hasLeading: !!(moduleMap && moduleMap[shift.module]?.hasLeading),
      isShortStaffed:
        (cohortMap?.[shift.cohort]?.students?.length ?? 0) /
          ((shift.leading.length as number) +
            (shift.supporting.length as number)) >
        (lessonMap[shift.lesson]?.ratio || 7),
    }));

  return (
    <Wrap>
      <Grid container style={{ height: '100%' }}>
        <Grid lg={3} sx={{ p: 2 }}>
          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={handleSetView}
            sx={{ mb: 2, width: '100%' }}
          >
            <ToggleButton value="all" sx={{ width: '50%' }}>
              All Shifts
            </ToggleButton>
            <ToggleButton value="mine" sx={{ width: '50%' }}>
              My Shifts
            </ToggleButton>
          </ToggleButtonGroup>
          {view === 'all' && (
            <Paper square sx={{ p: 2 }}>
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">Modules</FormLabel>
                <FormGroup>
                  {moduleMap &&
                    Object.entries(moduleMap)
                      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
                      .sort(([_a, a], [_b, b]) => a.order - b.order)
                      .map(([moduleId, mod]) => (
                        <FormControlLabel
                          key={moduleId}
                          control={
                            <Checkbox
                              checked={selectedModules.includes(moduleId)}
                              onChange={() => {
                                const newSelectedModules =
                                  selectedModules.includes(moduleId)
                                    ? selectedModules.filter(
                                        (selectedMod) =>
                                          selectedMod !== moduleId
                                      )
                                    : [...selectedModules, moduleId];

                                setSelectedModules(newSelectedModules);
                                window.localStorage.setItem(
                                  'selected-modules',
                                  JSON.stringify(newSelectedModules)
                                );
                              }}
                              name={mod.name}
                              sx={{
                                color: mod.color,
                                '&.Mui-checked': {
                                  color: mod.color,
                                },
                              }}
                            />
                          }
                          label={mod.name}
                        />
                      ))}
                </FormGroup>
              </FormControl>
            </Paper>
          )}
        </Grid>
        <Grid lg={9} sx={{ padding: 2 }}>
          <Paper sx={{ padding: 0, height: '100%' }} square>
            <Calendar
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              style={{ height: '100%' }}
              onSelectEvent={(shift) =>
                sideEntityController.open({
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  entityId: shift.id,
                  path: 'shifts',
                  collection: shiftsCollection,
                  width: 800,
                })
              }
              views={
                {
                  month: true,
                  list: CohortView,
                } as any
              }
              messages={
                {
                  list: 'Payroll',
                } as any
              }
              components={{
                event: DayEvent,
              }}
              showAllEvents
            />
          </Paper>
        </Grid>
      </Grid>
    </Wrap>
  );
};

export default Rota;
