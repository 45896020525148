/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { buildCollection, buildProperty } from '@camberi/firecms';

const shiftsCollection = buildCollection({
  path: 'shifts',
  initialSort: ['order', 'asc'],
  properties: {
    order: {
      name: 'Order',
      validation: { required: true },
      dataType: 'number',
      columnWidth: 150,
    },
    startDate: buildProperty({
      dataType: 'date',
      name: 'Start Date',
      mode: 'date_time',
      validation: { required: true },
      columnWidth: 190,
    }),
    endDate: buildProperty({
      dataType: 'date',
      name: 'End Date',
      mode: 'date_time',
      validation: { required: true },
      columnWidth: 190,
    }),
    cohort: {
      name: 'Cohort',
      validation: { required: true },
      dataType: 'reference',
      path: 'cohorts',
      columnWidth: 250,
    },
    module: {
      name: 'Module',
      validation: { required: true },
      dataType: 'reference',
      path: 'modules',
      columnWidth: 250,
    },
    lesson: {
      name: 'Lesson',
      dataType: 'reference',
      path: 'lessons',
      columnWidth: 250,
    },
    leading: {
      name: 'Leading',
      dataType: 'array',
      of: {
        dataType: 'reference',
        path: 'users',
        previewProperties: ['firstName', 'lastName'],
      },
    },
    supporting: {
      name: 'Supporting',
      dataType: 'array',
      of: {
        dataType: 'reference',
        path: 'users',
        previewProperties: ['firstName', 'lastName'],
      },
    },
  },
  name: 'Shifts',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  permissions: ({ authController }) => ({
    edit:
      !!authController.extra.roles.admin || !!authController.extra.roles.tutor,
    create:
      !!authController.extra.roles.admin || !!authController.extra.roles.tutor,
    delete:
      !!authController.extra.roles.admin || !!authController.extra.roles.tutor,
  }),
  inlineEditing: false,
  group: '01. Scheduling & Attendance',
});

export default shiftsCollection;
