/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { buildCollection, buildProperty } from '@camberi/firecms';

const cohortsCollection = buildCollection({
  path: 'cohorts',
  initialSort: ['startDate', 'desc'],
  properties: {
    name: buildProperty({
      dataType: 'string',
      name: 'Name',
      width: 200,
    }),
    startDate: buildProperty({
      dataType: 'date',
      name: 'Start Date',
      mode: 'date_time',
    }),
    endDate: buildProperty({
      dataType: 'date',
      name: 'End Date',
      mode: 'date_time',
    }),
    graduationDate: buildProperty({
      dataType: 'date',
      name: 'Graduation Date',
      mode: 'date_time',
    }),
    applicationDeadline: buildProperty({
      dataType: 'date',
      name: 'Application Deadline',
      mode: 'date_time',
    }),
    minimumDeposit: buildProperty({
      name: 'Minimum Deposit',
      dataType: 'number',
      validation: { required: true },
    }),
    financeMonthlyAmount: buildProperty({
      name: 'Finance Monthly From',
      dataType: 'number',
      validation: { required: true },
    }),
    diversityGrantExcVat: buildProperty({
      name: 'Diversity Grant Exc. VAT',
      dataType: 'map',
      properties: {
        eur: buildProperty({
          name: 'EUR',
          dataType: 'number',
          validation: { required: true },
        }),
        gbp: buildProperty({
          name: 'GBP',
          dataType: 'number',
          validation: { required: true },
        }),
      },
    }),
    totalExcVat: buildProperty({
      name: 'Total Exc. VAT',
      dataType: 'map',
      properties: {
        eur: buildProperty({
          name: 'EUR',
          dataType: 'number',
          validation: { required: true },
        }),
        gbp: buildProperty({
          name: 'GBP',
          dataType: 'number',
          validation: { required: true },
        }),
      },
    }),
    salePreviousPriceExcVat: buildProperty({
      name: 'Sale Previous Price Exc. VAT',
      dataType: 'map',
      properties: {
        eur: buildProperty({
          name: 'EUR',
          dataType: 'number',
        }),
        gbp: buildProperty({
          name: 'GBP',
          dataType: 'number',
        }),
      },
    }),
    saleTagline: buildProperty({
      dataType: 'string',
      name: 'Sale Tagline',
      width: 200,
    }),
    saleDisclaimer: buildProperty({
      dataType: 'string',
      name: 'Sale Disclaimer',
      width: 200,
    }),
    saleEndDate: buildProperty({
      dataType: 'date',
      name: 'Sale End Date',
      mode: 'date_time',
    }),
    earlyBirdDiscountExcVat: buildProperty({
      name: 'Early Bird Discount',
      dataType: 'map',
      properties: {
        upfrontAmount: buildProperty({
          name: 'Upfront Discount (exc. VAT)',
          dataType: 'number',
        }),
        instalmentsAmount: buildProperty({
          name: 'Instalments Discount (exc. VAT)',
          dataType: 'number',
        }),
        deadline: buildProperty({
          dataType: 'date',
          name: 'Deadline',
          mode: 'date_time',
        }),
      },
    }),
    studentTarget: buildProperty({
      name: 'Student Target',
      dataType: 'number',
      validation: { required: true },
    }),
    students: {
      name: 'Students',
      dataType: 'array',
      of: {
        dataType: 'reference',
        path: 'users',
        previewProperties: ['firstName', 'lastName'],
      },
    },
    enrollable: buildProperty({
      dataType: 'boolean',
      name: 'Allow Enrolment?',
    }),
    lessons: {
      name: 'Lessons',
      dataType: 'array',
      of: {
        dataType: 'map',
        properties: {
          startTime: buildProperty({
            name: 'Start Time',
            dataType: 'date',
            mode: 'date_time',
          }),
          endTime: buildProperty({
            name: 'End Time',
            dataType: 'date',
            mode: 'date_time',
          }),
          isDropIn: buildProperty({
            name: 'Is Drop-In?',
            dataType: 'boolean',
          }),
        },
      },
    },
  },
  name: 'Cohorts',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  permissions: ({ authController }) => ({
    edit: !!authController.extra.roles.admin,
    create: !!authController.extra.roles.admin,
    delete: !!authController.extra.roles.admin,
  }),
  inlineEditing: false,
  group: '03. Student Operations',
});

export default cohortsCollection;
