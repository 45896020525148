/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { buildCollection, buildProperty } from '@camberi/firecms';

const chaptersCollection = buildCollection({
  path: 'chapters',
  properties: {
    name: buildProperty({
      name: 'Name',
      validation: { required: true },
      dataType: 'string',
    }),
    slug: buildProperty({
      name: 'Slug',
      validation: { required: true },
      dataType: 'string',
    }),
    pages: {
      name: 'Pages',
      dataType: 'array',
      of: {
        dataType: 'reference',
        path: 'pages',
        previewProperties: ['title'],
      },
    },
  },
  name: 'Chapters',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  permissions: ({ authController }) => ({
    edit:
      !!authController.extra.roles.admin || !!authController.extra.roles.tutor,
    create:
      !!authController.extra.roles.admin || !!authController.extra.roles.tutor,
    delete:
      !!authController.extra.roles.admin || !!authController.extra.roles.tutor,
  }),
  inlineEditing: false,
  group: '02. Curriculum',
});

export default chaptersCollection;
