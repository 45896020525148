import styled from 'styled-components';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
export const Wrap = styled.div`
  height: 100%;

  .rbc-toolbar {
    padding: 16px;
    margin: 0;
  }

  .rbc-toolbar-label {
    font-size: 1.25rem;
    font-weight: 500;
  }

  .rbc-button-link {
    font-family: inherit;
  }

  .rbc-event {
    padding: 0;
    background: transparent;
  }

  .rbc-month-view {
    border: 0;
    border-top: 1px solid #ddd;
  }
`;
