/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { buildCollection, buildProperty } from '@camberi/firecms';

const usersCollection = buildCollection({
  path: 'users',
  forceFilter: {
    'customClaims.tutor': ['==', true],
  },
  properties: {
    firstName: buildProperty({
      name: 'First Name',
      validation: { required: true },
      dataType: 'string',
      columnWidth: 300,
    }),
    lastName: buildProperty({
      name: 'Last Name',
      validation: { required: true },
      dataType: 'string',
      columnWidth: 300,
    }),
    customClaims: buildProperty({
      dataType: 'map',
      spreadChildren: true,
      properties: {
        admin: buildProperty({
          name: 'Is Admin?',
          dataType: 'boolean',
          readOnly: true,
        }),
        tutor: buildProperty({
          name: 'Is Tutor?',
          dataType: 'boolean',
          readOnly: true,
        }),
      },
    }),
  },
  name: 'Users',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  permissions: ({ authController }) => ({
    edit: false,
    create: false,
    delete: false,
  }),
  inlineEditing: false,
  group: '03. Student Operations',
});

export default usersCollection;
